import axios from 'axios';

const baseUrl = process.env.GATSBY_SITE_API_URL || '';

export const postFeedback = async (message) => {
  const result = await axios({
    method: 'POST',
    url: `${baseUrl}/feedback`,
    data: message,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
  return result;
};
