import React, { useState, useRef } from 'react';
import propTypes from 'prop-types';
import { CheckboxField, Text as TextField } from '@periodica/ui-kit';
import { Button } from '@components/Button';
import { OneColumn } from '@components/Layouts/OneColumn';
import SEO from '@components/Layouts/SEO';
import styles from './ClaimForm.module.scss';

export function ClaimForm({ order, vote, phone, fullname, email, onSubmit, isLoading }) {
  const textInput = useRef(null);
  const orderInput = useRef(null);
  const fullnameInput = useRef(null);
  const phoneInput = useRef(null);
  const voteInput = useRef(null);
  const emailInput = useRef(null);

  const [text, setText] = useState('');
  const [isQualityCb, setIsQualityCb] = useState(false);
  const [isDeliveryCb, setIsDeliveryCb] = useState(false);
  const [isPackCb, setIsPackCb] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let comment = textInput.current.value;
    comment += isQualityCb ? '<br/>+ Претензия к качеству печати' : '';
    comment += isDeliveryCb ? '<br/>+ Претензия к службе доставки' : '';
    comment += isPackCb ? '<br/>+ Претензия к упаковке' : '';
    onSubmit({
      vote: parseInt(voteInput.current.value, 10) || 0,
      phone: phoneInput.current.value,
      full_name: fullnameInput.current.value,
      email: emailInput.current.value,
      order: orderInput.current.value,
      comment,
    });
  };

  return (
    <OneColumn>
      <SEO
        title="Оставьте свой отзыв о работе Периодики"
        description="Мы обрабатываем каждый отзыв и учитываем ваши пожелания в работе."
      />

      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.title}>Расскажите, что случилось?</div>
          <div className={styles.description}>
            Расскажите, что случилось и испортило впечатление о заказе? Наш менеджер свяжется с вами
            в рабочее время (с 10:00 до 19:00) и поможет решить возникшую проблему.
          </div>
        </div>
        <form className={styles.form}>
          <input type="hidden" ref={voteInput} name="vote" defaultValue={vote} />
          <input type="hidden" ref={emailInput} name="email" defaultValue={email} />
          <input type="hidden" ref={orderInput} defaultValue={order} name="order" />
          <input type="hidden" ref={phoneInput} defaultValue={phone} name="phone" />
          <input type="hidden" ref={fullnameInput} defaultValue={fullname} name="fullname" />
          <div className={styles.checkboxes}>
            <CheckboxField
              title="Качество печати"
              onChange={(e) => setIsQualityCb(e.target.checked)}
              checked={isQualityCb}
            />
            <div className={styles.checkboxBlock}>
              <CheckboxField
                title="Работа курьерской службы"
                onChange={(e) => setIsDeliveryCb(e.target.checked)}
                checked={isDeliveryCb}
              />
            </div>
            <div className={styles.checkboxBlock}>
              <CheckboxField
                title="Упаковка и внешний вид заказа"
                onChange={(e) => setIsPackCb(e.target.checked)}
                checked={isPackCb}
              />
            </div>
          </div>
          <TextField
            label="Комментарий"
            inputProps={{ rows: 4 }}
            ref={textInput}
            value={text}
            onChange={setText}
          />
          <Button
            title="Отправить"
            customClassName={styles.buttonStyles}
            onClick={handleSubmit}
            disabled={!(isQualityCb || isDeliveryCb || isPackCb || text) || isLoading}
          />
        </form>
      </div>
    </OneColumn>
  );
}
ClaimForm.propTypes = {
  order: propTypes.number.isRequired,
  vote: propTypes.number.isRequired,
  phone: propTypes.string.isRequired,
  fullname: propTypes.string.isRequired,
  email: propTypes.string.isRequired,
  onSubmit: propTypes.func.isRequired,
};
