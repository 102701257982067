import React, { useRef } from 'react';
import propTypes from 'prop-types';
import { Text as TextField } from '@periodica/ui-kit';
import SEO from '@components/Layouts/SEO';
import { Button } from '@components/Button';
import { OneColumn } from '../../Layouts/OneColumn';

import styles from './PositiveReviewForm.module.scss';

export function PositiveReviewForm({ order, vote, phone, fullname, email, onSubmit, isLoading }) {
  const textInput = useRef(null);
  const orderInput = useRef(null);
  const fullnameInput = useRef(null);
  const phoneInput = useRef(null);
  const voteInput = useRef(null);
  const emailInput = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const comment = textInput.current.value;
    onSubmit({
      vote: parseInt(voteInput.current.value, 10) || 0,
      phone: phoneInput.current.value,
      full_name: fullnameInput.current.value,
      email: emailInput.current.value,
      order: orderInput.current.value,
      comment,
    });
  };

  return (
    <OneColumn>
      <SEO
        title="Оставьте свой отзыв о работе Периодики"
        description="Мы обрабатываем каждый отзыв и учитываем ваши пожелания в работе."
      />
      <div className={styles.container}>
        <div className={styles.main}>
          <div>
            <div className={styles.title}>Спасибо за высокую оценку </div>
            <div className={styles.description}>
              Рады, что всё прошло хорошо. Нам важно знать ваше мнение. Будем благодарны, если вы
              оставите отзыв о нашем сервисе.
            </div>
          </div>
          <form className={styles.form} onSubmit={handleSubmit}>
            <input type="hidden" ref={voteInput} name="vote" defaultValue={vote} />
            <input type="hidden" ref={emailInput} name="email" defaultValue={email} />
            <input type="hidden" ref={phoneInput} name="phone" defaultValue={phone} />
            <input type="hidden" ref={fullnameInput} name="vote" defaultValue={fullname} />
            <input type="hidden" ref={orderInput} name="order" defaultValue={order} />
            <TextField name="text" label="Комментарий" ref={textInput} inputProps={{ rows: 4 }} />
            <Button
              title="Отправить"
              customClassName={styles.buttonStyles}
              onClick={handleSubmit}
              disabled={isLoading}
            />
          </form>
        </div>
      </div>
    </OneColumn>
  );
}
PositiveReviewForm.propTypes = {
  order: propTypes.number.isRequired,
  vote: propTypes.number.isRequired,
  phone: propTypes.string.isRequired,
  fullname: propTypes.string.isRequired,
  email: propTypes.string.isRequired,
  onSubmit: propTypes.func.isRequired,
};
