import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import Icon from '@images/icons/icon-review.svg';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import Layout from '../components/Layouts/Layout';
import { OneColumn } from '../components/Layouts/OneColumn';
import { ClaimForm } from '../components/Forms/Feedback/ClaimForm';
import { PositiveReviewForm } from '../components/Forms/Feedback/PositiveReviewForm';
import { postFeedback } from '../periodicaApi/feedback';
import SEO from '../components/Layouts/SEO';

import styles from './claim.module.scss';

const getOrderInfoFromBase64 = (base64string) => {
  try {
    return decodeURIComponent(escape(atob(base64string)));
  } catch (err) {
    return null;
  }
};

export function Head() {
  return (
    <>
      <SEO
        title="Спасибо за ваш отзыв!"
        description="Мы обрабатываем каждый отзыв и учитываем ваши пожелания в работе."
      />
      <YandexTableau />
    </>
  );
}

function FeedbackPage() {
  const [formData, setFormData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    try {
      const query = typeof window !== 'undefined' ? window.location.search.replace('?', '') : null;
      if (query) {
        const result = new URLSearchParams(window.location.search);
        const urlInfo = result.get('info');
        const urlStar = result.get('star');
        const data = JSON.parse(getOrderInfoFromBase64(urlInfo));
        if (data) {
          setFormData({ ...data, vote: parseInt(urlStar || 1, 10) });
        } else {
          navigate('/404');
        }
      }
    } catch (e) {
      console.debug(e);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isSubmitted, isSubmitError]);

  const onSubmit = async (message) => {
    setIsLoading(true);
    try {
      await postFeedback(message);
      setIsSubmitted(true);
    } catch (e) {
      setIsSubmitError(true);
    }
    setIsLoading(false);
  };

  const { order, vote, email, phone, fullname } = formData;
  return (
    <Layout>
      {isSubmitError && (
        <OneColumn>
          <p className={styles.description}>Что-то пошло не так. Попробуйте обновить страницу.</p>
        </OneColumn>
      )}
      {!isSubmitError && !isSubmitted && (
        <>
          {vote < 4 && (
            <ClaimForm
              onSubmit={onSubmit}
              fullname={fullname}
              email={email}
              phone={phone}
              vote={vote}
              order={order}
              isLoading={isLoading}
            />
          )}
          {vote >= 4 && (
            <PositiveReviewForm
              onSubmit={onSubmit}
              fullname={fullname}
              email={email}
              phone={phone}
              vote={vote}
              order={order}
              isLoading={isLoading}
            />
          )}
        </>
      )}
      {!isSubmitError && isSubmitted && (
        <OneColumn>
          <div className={styles.main}>
            <div className={styles.cirlceBlock}>
              <div className={styles.lightCircle}>
                <div className={styles.blueCircle}>
                  <img className={styles.img} src={Icon} />
                </div>
              </div>
            </div>
            <div className={styles.header}>
              <div className={styles.title}>Спасибо за ваш отзыв!</div>
              <div className={styles.description}>
                Мы обрабатываем каждый отзыв и учитываем ваши пожелания в работе. Вернуться на&nbsp;
                <Link className={styles.link} to="/">
                  главную
                </Link>
                .
              </div>
            </div>
            {vote >= 4 && (
              <div className={styles.descriptionLinks}>
                Вы также можете оставить отзыв на&nbsp;
                <a
                  className={styles.link}
                  href="https://irecommend.ru/srch?query=%D0%BF%D0%B5%D1%80%D0%B8%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0"
                  target="_blank"
                  rel="noreferrer"
                >
                  iRecommend.ru
                </a>
                . Если вы совершили заказ из мобильного приложения, пожалуйста, оцените его в&nbsp;
                <a
                  className={styles.link}
                  href="https://apps.apple.com/ru/app/%D0%BF%D0%B5%D1%80%D0%B8%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0-%D0%BF%D0%B5%D1%87%D0%B0%D1%82%D1%8C-%D1%84%D0%BE%D1%82%D0%BE%D0%BA%D0%BD%D0%B8%D0%B3/id1005018803?mt=8"
                  target="_blank"
                  rel="noreferrer"
                >
                  App Store
                </a>
                .
              </div>
            )}
          </div>
        </OneColumn>
      )}
    </Layout>
  );
}
export default FeedbackPage;
